'use client'
import { useEffect, useMemo } from 'react'

import { create } from 'zustand/react'

import { pick } from '@zag-js/utils'

import { DEFAULT_REGION } from '~/utils/consts'
import { COOKIE, getCookieAsParsedJson, setCookie } from '~/utils/cookies'
import { getClosestRegionByGeolocation } from '~/utils/getClosestRegionByGeolocation'
import { getLogger } from '~/utils/logger'

import { Region, useRegionsQuery } from '~/app/generated/graphql'
import { gt } from '~/lib/gt'

type LeanRegion = Omit<Region, 'zones'>

type SelectedRegion = {
  id: string
  name: string
}

type LocationCookie = SelectedRegion & { isProposed: boolean }

interface UseLocation {
  loading: boolean
  selectedRegion: SelectedRegion | null
  regions: LeanRegion[]
  setRegion(region: LeanRegion): void
  getRegion(id: string): LeanRegion | undefined
}

export const locationLogger = getLogger('location', 'debug')

export const useSelectedRegion = create<{
  region: SelectedRegion | null
  setRegion: (region: SelectedRegion) => void
  init: (regions: Omit<Region, 'zones'>[]) => void
  isInitialized: boolean
}>((setState, getState) => ({
  region: DEFAULT_REGION,
  setRegion: (region: SelectedRegion) => {
    const selectedRegion = pick(region, ['id', 'name'])
    setState(_ => ({ region: selectedRegion }))
    setCookie(COOKIE.LOCATION, JSON.stringify(selectedRegion))
  },
  isInitialized: false,
  init: async regions => {
    if (getState().isInitialized) return

    const locationCookie = getCookieAsParsedJson(COOKIE.LOCATION) as LocationCookie

    const isLocationCookieValid = !!locationCookie && regions.map(r => r.id).includes(locationCookie.id)

    if (isLocationCookieValid) {
      getState().setRegion(pick(locationCookie, ['id', 'name']))
    } else {
      const closestRegion = await getClosestRegionByGeolocation(regions)

      getState().setRegion(closestRegion)
      locationLogger(`Set region by geolocation, set selected region id: ${closestRegion.id}`)
    }

    setState(() => ({ isInitialized: true }))
  },
}))

export const useLocation = (): UseLocation => {
  const selectedRegion = useSelectedRegion()

  const { data: dataRegions, loading } = useRegionsQuery({
    nextFetchPolicy: 'cache-first',
  })

  const regions = useMemo(
    () =>
      dataRegions?.regions?.map(region =>
        region.id === DEFAULT_REGION.id ? { ...region, name: gt.tp('Locations', DEFAULT_REGION.name) } : region
      ),
    [dataRegions?.regions]
  )

  const getRegion = (id: string) => {
    return (regions || []).find(r => r.id === id)
  }

  useEffect(() => {
    if (!regions) return

    selectedRegion.init(regions)
  }, [regions, selectedRegion])

  return {
    regions: regions || [],
    loading,
    selectedRegion: selectedRegion.region,
    setRegion: selectedRegion.setRegion,
    getRegion,
  }
}
